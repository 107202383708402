import { UserData } from "./types";

export function getDefaultUserData(): UserData {
    return {
        posX: Math.random() * 400,
        posY: Math.random() * 400,
        radius: Math.random() * 20 + 10,
        holding: false,
        inactive: false,
    }
}