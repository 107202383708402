// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { DatabaseReference, get, getDatabase, onDisconnect, onValue, ref, set, update } from "firebase/database";
import { getAuth, onAuthStateChanged, signInAnonymously } from "firebase/auth"
import { User, UserData } from "./types";
import { getDefaultUserData } from "./utils";

/**
 * Firebase setup
 */

const firebaseConfig = {
  apiKey: "AIzaSyDpIb_MwUJgcI9_kSKgPofugatJ2gX2ZpQ",
  authDomain: "arcade-matthewgraham-me.firebaseapp.com",
  databaseURL: "https://arcade-matthewgraham-me.firebaseio.com",
  projectId: "arcade-matthewgraham-me",
  storageBucket: "arcade-matthewgraham-me.appspot.com",
  messagingSenderId: "228000157058",
  appId: "1:228000157058:web:6291585f48021671dd539b",
  measurementId: "G-RW0VSQ8JVN"
};
const app = initializeApp(firebaseConfig);
const auth = getAuth();
signInAnonymously(auth)

/**
 * private declarations
 */
const dbRoot = `/hangout`
const db = getDatabase(app)
const usersRef = ref(db, `${dbRoot}/users`)
let userRef: DatabaseReference | undefined;

/**
 * public declarations
 */

export let userId: string | undefined
export let usersMap: Map<string, User> | undefined
export let user: User | undefined

/**
 * private functions
 */
function setUser(user: UserData) {
    if (userRef) {
        set(userRef, user)
    }
}


/**
 * public functions
 */
export function updateUser(updates: Partial<UserData>) {
    if (userRef) {
        update(userRef, updates)
    }
}

let nextUpdateId: NodeJS.Timeout
let lastUpdateMs: number = new Date().getTime()
/**
 * Rate limits updates to the user
 */
export function limitedUpdateUser(updates: Partial<UserData>) {
    clearTimeout(nextUpdateId)

    const nowMs = new Date().getTime()
    const timeDifferenceMs = nowMs - lastUpdateMs
    const timeUntilExecuteMs = 20 - timeDifferenceMs

    if (timeUntilExecuteMs > 0) {
        nextUpdateId = setTimeout(() => {
            updateUser(updates)
            lastUpdateMs = nowMs
        }, timeUntilExecuteMs)
    } else {
        updateUser(updates)
        lastUpdateMs = nowMs
    }
}


/**
 * Listeners 
 */

// Authentication
onAuthStateChanged(auth, (authUser) => {
    // UserData is signed in
    if (authUser) {
        // UserData has changed ids (possibly a new user)
        if (userId !== authUser.uid) {
            userId = authUser.uid
            const nextUserRef = ref(db, `${dbRoot}/users/${userId}`)

            // update the user ref
            userRef = nextUserRef
            
            // determine if I should create a new user or update the existing on
            get(userRef).then(snapshot => {
                const existingUser = snapshot.val()
                // set the initial data of the user
                if (!existingUser) {
                    const nextUserData = getDefaultUserData()
                    setUser(nextUserData)
                    user = {...nextUserData, dx: 0, dy: 0}
                } 
                // set the existing user to inactive
                else {
                    updateUser({ inactive: false })
                    user = { ...existingUser, inactive: false }
                }
            })
            
            // handle when the user disconnects
            onDisconnect(userRef).update({ inactive: true })
        }
    } 
    // UserData is signed out
    else {
        userId = undefined
        if (userRef) {
            updateUser({ inactive: true })
        }
        userRef = undefined
        user = undefined
    }
});

// Users
onValue(usersRef, (snapshot) => {
    const nextUsersMap = new Map<string, User>()
    snapshot.forEach((child) => {
      if (child.key) {
        const prevUser = usersMap?.get(child.key)
        const nextUserData: UserData = child.val()
        const nextUser: User = {
            ...nextUserData, 
            dx: prevUser?.posX !== undefined ? nextUserData.posX - prevUser.posX : 0,
            dy: prevUser?.posY !== undefined ? nextUserData.posY - prevUser.posY : 0,
        }
        nextUsersMap.set(child.key, nextUser)
      }
    })
    usersMap = nextUsersMap
    if (userId) {
        user = usersMap.get(userId)
    }
})
