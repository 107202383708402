import React from 'react';
import Sketch from "react-p5";
import p5Types from "p5";
import './App.css';
import { limitedUpdateUser, updateUser, user, userId, usersMap } from './firebase';

let bubbles: { createdFrame: number; posX: number; posY: number; angle: number; radius: number }[] = []
function App() {
  const setup = (p5: p5Types, canvasParentRef: Element) => {
		p5.createCanvas(p5.windowWidth, p5.windowHeight).parent(canvasParentRef);
	};

  const windowResized = (p5: p5Types) => {
    p5.resizeCanvas(p5.windowWidth, p5.windowHeight)
  }

	const draw = (p5: p5Types) => {
		p5.background(0);

    // remove old bubbles
    bubbles = bubbles.filter(bubble => p5.frameCount - bubble.createdFrame < 100)
    // update and draw bubbles
    bubbles.forEach(bubble => {
      // update 
      bubble.radius += .05
      bubble.angle += p5.random(-.1, .1)
      bubble.posX += Math.cos(bubble.angle)
      bubble.posY += Math.sin(bubble.angle)
      // draw
      const colorFill = p5.color("aqua")
      const colorStroke = p5.color("aqua")
      colorFill.setAlpha(p5.map(p5.frameCount - bubble.createdFrame, 0, 100, 125, 0))
      colorStroke.setAlpha(p5.map(p5.frameCount - bubble.createdFrame, 0, 100, 135, 10))
      p5.fill(colorFill)
      p5.stroke(colorStroke)
      p5.strokeWeight(3)
      p5.circle(bubble.posX, bubble.posY, bubble.radius*2)
    })

    const inactiveColor = p5.color(130,130,130,100)
    const userColor = p5.color(255,255,255,180)
    // draw users
    usersMap?.forEach((user, id) => {
      // spawn bubbles roughly every ten frames if user is active
      if (!user.inactive) {
        const vel = p5.createVector(user.dx, user.dy).mag()
        const bubbleEveryXFrames = Math.floor(p5.map(
          vel,
          0,
          20,
          20,
          2,
          true
        ))
        if (p5.frameCount % bubbleEveryXFrames === 0) {
          bubbles.push({ 
            createdFrame: p5.frameCount, 
            posX: user.posX, 
            posY: user.posY, 
            angle: p5.random(p5.TWO_PI),
            radius: p5.random(5, 10)
          })
        }
      }

      // fill color
      if (user.inactive) {
        p5.fill(inactiveColor)
      } else {
        p5.fill(userColor)
      }

      // stroke color
      if (user.holding) {
        p5.strokeWeight(3)
        p5.stroke("lightgreen")
      } else {
        p5.noStroke()
      }

      // body
      p5.circle(user.posX, user.posY, user.radius * 2);

      // if is current user
      if (userId === id) {
        p5.noStroke()
        p5.fill("black")
        p5.circle(user.posX, user.posY, 5)
      }
    })
	};

  const mousePressed = (p5: p5Types) => {
    if (user) {
      const distToUser = p5.dist(user.posX, user.posY, p5.mouseX, p5.mouseY)
      if (distToUser < user.radius) {
        updateUser({ holding: !user.holding })
      }
    }
  }

  const mouseMoved = (p5: p5Types) => {
    if (user && user.holding) {
      limitedUpdateUser({
        posX: p5.mouseX,
        posY: p5.mouseY
      })
    }
    
  }

  return (<Sketch setup={setup} draw={draw} windowResized={windowResized} mousePressed={mousePressed} mouseMoved={mouseMoved} />);
}

export default App;
